import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAzIjO_L_tXRnaT4MxKqwsDnSgXB_EeT6M",
  authDomain: "chatapp-888.firebaseapp.com",
  databaseURL: "https://chatapp-888.firebaseio.com",
  projectId: "chatapp-888",
  storageBucket: "chatapp-888.appspot.com",
  messagingSenderId: "917147576252",
  appId: "1:917147576252:web:8572072784f5ad39c4edb9",
  measurementId: "G-5S2XQTNRPF",
};

const firebaseApp = initializeApp(firebaseConfig);

const database = getDatabase(firebaseApp);
const authentication = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();

export { database, authentication, provider };
