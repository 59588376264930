import TrendingTab from "./tabs/TrendingTab";
import LatestTab from "./tabs/LatestTab";
import MostReadTab from "./tabs/MostReadTab";
import BookmarkTab from "./tabs/BookmarkTab";
import "./styles/NewsTab.css";
import { useStateValue } from "../StateProvider";

function NewsTab() {
  const [{ tab }, dispatch] = useStateValue();

  const renderTabScreen = () => {
    switch (tab) {
      case 1:
        return <TrendingTab />;
        break;

      case 2:
        return <LatestTab />;
        break;

      case 3:
        return <MostReadTab />;
        break;

      case 4:
        return <BookmarkTab />;
        break;

      default:
        break;
    }
  };
  return <>{renderTabScreen()}</>;
}

export default NewsTab;
