import Watchlist from "./screens/Watchlist";
import ChatWindow from "./screens/ChatWindow";
import ChatGroup from "./screens/ChatGroup";
import NewsTab from "./screens/NewsTab";
import Sidebar from "./screens/Sidebar";
import { useEffect, useState } from "react";
import { useStateValue } from "./StateProvider";
import Login from "./screens/Login";
import { database } from "./firebase";
import { actionTypes } from "./reducer";
import UnderMaintenance from "./screens/UnderMaintenance";
import { onValue, ref } from "firebase/database";
import SearchScreen from "./screens/SearchScreen";

function App() {
  //console.log(process.env.REACT_APP_SERVER_ADDRESS);
  const [{ user, chat, config, send, search }, dispatch] = useStateValue();
  const [maintenance, setmaintenance] = useState(false);
  const [isshare, setisshare] = useState(false);

  useEffect(() => {
    const messageInfo = ref(database, `/config/`);
    onValue(messageInfo, async (snapshot) => {
      if (!snapshot.exists) {
        setmaintenance(false);
      } else {
        let snapVal = snapshot.val();
        setmaintenance(snapVal.maintenance);
        dispatch({
          type: actionTypes.SET_CONFIG,
          config: snapVal,
        });
      }
    });
  }, []);

  const mobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  useEffect(() => {
    const fetchData = async () => {
      let storeduser = localStorage.getItem("user");
      if (storeduser == []) {
      } else {
        dispatch({
          type: actionTypes.SET_USER,
          user: JSON.parse(storeduser),
        });
      }

      if (window.location.pathname.startsWith("/share/")) {
        if (mobileCheck()) {
          setisshare(true);
          window.open("opiniontrade:" + window.location.pathname);
        } else {
          if (JSON.parse(storeduser)){
          try {
            let response = await fetch(
              `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/getNews?news=${window.location.pathname.replace(
                "/share/",
                ""
              )}`
            );
            let dataJSON = await response.json();

            if (dataJSON && dataJSON.length > 0) {
              let domain = new URL(dataJSON[0].newsLink);
              let sharedSite = domain.hostname;
              let news = {
                newsTitle: dataJSON[0].newsTitle,
                newsSite: sharedSite,
                newsLink: dataJSON[0].newsLink,
                aisummary: dataJSON[0].aisummary
              };
              dispatch({
                type: actionTypes.SET_SHARED_NEWS,
                news,
              });
              dispatch({
                type: actionTypes.SEND_NEWS,
                sendenabled: true,
                news,
                room: [],
              });
            } else {
              throw new Error('Empty data from first API');
            }
          } catch (error) {
            console.error('Error fetching data from first API:', error);

            try {
              let response = await fetch(`https://generativeai.opiniontrade.ai/api/scrape?url=${window.location.pathname.replace("/share/","")}`);
              let dataJSON = await response.json();
              let domain = new URL(window.location.pathname.replace("/share/", ""));
              let sharedSite = domain.hostname;
              // Await the result of fetchStockQuotes
              let refStockQuote = await fetchStockQuotes(dataJSON.associatedStocks);
              let aisummary = dataJSON.summary
              let news = {
                newsTitle: dataJSON.title,
                newsSite: sharedSite,
                newsLink: window.location.pathname.replace("/share/", ""),
                refStockQuote: refStockQuote,
                aisummary: aisummary
              };
              dispatch({
                type: actionTypes.SET_SHARED_NEWS,
                news,
              });
              dispatch({
                type: actionTypes.SEND_NEWS,
                sendenabled: true,
                news,
                room: [],
              });
            } catch (error) {
              console.error('Error fetching data from second API:', error);
              let domain = new URL(window.location.pathname.replace(
                "/share/",
                ""
              ));
              let sharedSite = domain.hostname;
              let news = {
                newsTitle: sharedSite,
                newsSite: sharedSite,
                newsLink: sharedSite,
              };
              dispatch({
                type: actionTypes.SET_SHARED_NEWS,
                news,
              });
              dispatch({
                type: actionTypes.SEND_NEWS,
                sendenabled: true,
                news,
                room: [],
              });
            }
          }
          }else{
            let domain = new URL(window.location.pathname.replace(
              "/share/",
              ""
            ));
            let sharedSite = domain.hostname;
            let response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/title?news=${window.location.pathname.replace(
              "/share/",
              "")}`);
            let dataJSON = await response.json();
            let news = {
              newsTitle: dataJSON.title,
              newsSite: sharedSite,
              newsLink: dataJSON.url,
            };
            dispatch({
              type: actionTypes.SET_SHARED_NEWS,
              news,
            });
            dispatch({
              type: actionTypes.SEND_NEWS,
              sendenabled: true,
              news,
              room: [],
            });
          }
        }
      }
    };

    fetchData();
  }, []);

  const fetchStockQuotes = async(refStock) => {
    let refStockQuote = []
    if (refStock) {
      let url = `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/stocksQuote?ticker=${refStock.replace(/, /g, "&ticker=")}`;
      console.log('url!', url);
      try {
        let response = await fetch(url);
        let dataJson = await response.json();
        refStockQuote = dataJson;
      } catch (err) {
        console.log('fetching stock quote error', err);
        refStockQuote = [];
      }
    }

    console.log('refstockquote', refStockQuote)
    return refStockQuote;
  };

  return (
    <main className="flex flex-col w-screen h-screen">
      {/* <Header /> */}
      {maintenance ? (
        <UnderMaintenance />
      ) : (
        <div className="flex flex-col sm:flex-row w-screen h-screen">
          {send.enabled ? (
            <div
              className="w-full h-full absolute bg-black opacity-[50%] z-[99]"
              onClick={() => {
                dispatch({
                  type: actionTypes.SEND_NEWS,
                  sendenabled: false,
                  news: [],
                  room: [],
                });
                dispatch({
                  type: actionTypes.SET_SHARED_NEWS,
                  news: {
                    newsTitle: "",
                    newsLink: "",
                    newsSite: "",
                  },
                });
                dispatch({
                  type: actionTypes.SET_COMMENT,
                  comment: "",
                });
              }}
            ></div>
          ) : null}
          {search.enabled ? <SearchScreen /> : null}
          <Sidebar />
          <NewsTab />
          {user ? <ChatGroup /> : null}
          {user && chat.enabled ? <ChatWindow /> : null}
          {user ? <Watchlist /> : <Login />}
        </div>
      )}
      {/* <Footer /> */}
      {isshare ? (
        <div className="w-full h-full flex sm:hidden absolute items-end backdrop-blur-sm z-50">
          <div className="bg-white w-full h-64 flex justify-center items-center flex-col rounded-tl-[10px] rounded-tr-[10px] border-t-4 border-[#1ab394]">
            <div className="flex flex-row justify-center items-center w-16 mx-auto my-2 sm:my-10 p-2 bg-white rounded-2xl text-white z-20">
              <img
                src={require("./assets/logo.png")}
                loading="lazy"
                className="h-full w-full object-cover"
              />
              <p className="text-black relative sm:hidden ml-2 text-xl font-bold text-[#1ab394]">
                OpinionTrade
              </p>
            </div>
            <p className="text-xl font-bold mb-6">
              Get the full experience on the app
            </p>
            <div
              className="bg-[#1ab394] text-white rounded mb-4 py-[10px] px-[50px]"
              onClick={() => {
                setTimeout(function () {
                  window.location = config.applink;
                }, 25);
                window.location = "opiniontrade:" + window.location.pathname;
              }}
            >
              <p className="">Open OpinionTrade</p>
            </div>
            <div className="" onClick={() => setisshare(false)}>
              <p className="">Not now</p>
            </div>
          </div>
        </div>
      ) : null}
    </main>
  );
}

export default App;
