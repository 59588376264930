import React from "react";

function UnderMaintenance() {
  return (
    <div className="w-full h-full flex flex-col justify-between items-center bg-white">
      <div className="flex flex-row justify-center items-center w-16 mx-auto mt-4 sm:mt-10 p-2 bg-white rounded-2xl text-white z-20">
        <img
          src={"https://opiniontrade-webchat.herokuapp.com/logo192.png"}
          loading="lazy"
          className="h-12 w-12 object-cover"
        />
        <p className="text-black relative ml-2 text-xl font-bold text-[#1ab394]">
          OpinionTrade
        </p>
      </div>
      <div className="flex flex-1 justify-center items-center mb-0 mt-0 sm:mt-10">
        <img
          src={
            "https://cdn.dribbble.com/users/1023572/screenshots/5298652/gtap_maint_dribbble.gif"
          }
          loading="lazy"
          className="w-90 sm:w-1/2 object-cover"
        />
      </div>
      <div className="flex flex-1 flex-col justify-center items-center">
        <p className="text-[30px] sm:text-[40px] text-[#1ab394] font-bold">
          We’ll be back soon!
        </p>
        <p className="mt-3 text-[14px] sm:text-[20px] text-center px-[10%] sm:px-[30%]">
          Sorry for the inconvenience. We’re performing some maintenance at the
          moment, we’ll be back up shortly!
        </p>
      </div>
    </div>
  );
}

export default UnderMaintenance;
