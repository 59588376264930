import "./styles/NewsStockSlider.css";
import { ref, set } from "firebase/database";
import { database } from "../firebase";
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function NewsStockSlider({ stockArr, storedsymbols = [], index }) {
  const [{ user, config, selectedstocks }, dispatch] = useStateValue();
  const changePercent = stockArr.changePercent * 100;
  const isUp = changePercent > 0;
  let isAddedToWatchlist = storedsymbols.includes(stockArr.symbol);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltipArrow: {
            backgroundColor: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
            padding: 0,
            borderRadius: 10,
            minWidth: 250,
          },
        },
      },
    },
  });

  const addStock = (stock) => {
    if (selectedstocks.includes(stock)) {
    } else {
      dispatch({
        type: actionTypes.SET_SELECTED_STOCK,
        stock: stock,
      });
      setloading(true);
    }
  };

  if (stockArr === undefined) return null;

  if (index > 5) return null;

  return (
    <div key={index}>
      <ThemeProvider theme={theme}>
        <Tooltip
          title={
            <>
              <div
                className="pop_card rounded-t-[10px]"
                style={{
                  display: "flex",
                  padding: 10,
                  alignItems: "center",
                  backgroundColor: isUp ? "#1ab394" : "#f44336",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingRight: 0,
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontSize: 18,
                    fontWeight: "600",
                  }}
                >
                  {stockArr.symbol}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: isUp ? "#1ab394" : "#f44336",
                    padding: 5,
                    // borderRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <i
                    className={`fas ${
                      isUp ? "fa-arrow-circle-up" : "fa-arrow-circle-up"
                    }`}
                    style={{ marginRight: 3 }}
                  ></i>
                  <p
                    style={{
                      fontSize: 16,
                      color: "#fff",
                    }}
                  >
                    {" "}
                    {changePercent.toFixed(2)}%{" "}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: 10,
                  backgroundColor: isUp ? "#1ab394" : "#f44336",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: 0,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    marginRight: 20,
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      fontSize: 14,
                    }}
                  >
                    {stockArr.companyName}
                  </p>
                </div>
                <div
                  style={{
                    alignItems: "flex-end",
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      fontSize: 14,
                    }}
                  >
                    ${stockArr.latestPrice}
                  </p>
                </div>
              </div>
              <div className="bg-white rounded-b-[10px]">
                <div
                  style={{
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`cursor-pointer ${
                    user ? "hidden sm:flex" : "hidden"
                  } ${isAddedToWatchlist ? "bg-gray-100" : ""}`}
                  onClick={() => {
                    if (isAddedToWatchlist) {
                      set(
                        ref(
                          database,
                          `/users/${user.phoneNumber}/watchlist/${stockArr.symbol}/`
                        ),
                        null
                      );
                    } else {
                      set(
                        ref(
                          database,
                          `/users/${user.phoneNumber}/watchlist/${stockArr.symbol}/`
                        ),
                        {
                          ...stockArr,
                          enabled: true,
                        }
                      );
                    }
                  }}
                >
                  {isAddedToWatchlist ? (
                    <p className="text-[12px] text-[#5b5b5b] font-bold">
                      Remove {stockArr.symbol} from Watchlist
                    </p>
                  ) : (
                    <p className="cursor-pointer text-[12px] text-[#5b5b5b]">
                      Add {stockArr.symbol} to Watchlist
                    </p>
                  )}
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    addStock(stockArr.symbol);
                    dispatch({
                      type: actionTypes.SET_TAB,
                      tab: 1,
                    });
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    Trending News for {stockArr.symbol}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    addStock(stockArr.symbol);
                    dispatch({
                      type: actionTypes.SET_TAB,
                      tab: 2,
                    });
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    Latest News for {stockArr.symbol}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    addStock(stockArr.symbol);
                    dispatch({
                      type: actionTypes.SET_TAB,
                      tab: 3,
                    });
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    Most Read News for {stockArr.symbol}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    window.open(
                      `${config.watchlistLaunchUrlPre}${stockArr.symbol}${config.watchlistLaunchUrlPost}`,
                      "_blank",
                      "toolbar=0,location=0,menubar=0,left=500px,top=50px,height=600,width=800"
                    );
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    {config.stockPopupMessagePre}
                    {stockArr.symbol}
                    {config.stockPopupMessagePost}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
              </div>
            </>
          }
          placement="top"
          className="my-tooltip"
          arrow
        >
          <div
            key={index}
            data-tip="custom theme"
            data-for={stockArr.symbol}
            data-event="click focus"
            data-event-off="dblclick"
          >
            <div
              className={`flex ${
                isUp ? "border-2 border-[#1ab394]" : "border-2 border-[#f44336]"
              } px-[12px] py-[1px] items-center rounded-full mr-[3px] hover:bg-white hover:z-100`}
            >
              <div
                className={`flex mr-[5px] ${
                  isUp ? "bg-[#1ab394]" : "bg-[#f44336]"
                } p-[5px] ml-[-10px] justify-center items-center rounded-full`}
              >
                <div className="w-4 h-4 mr-1">
                  {isUp ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="#fff"
                    >
                      <path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 254.6c-12.5 12.5-32.75 12.5-45.25 0L288 205.3V384c0 17.69-14.33 32-32 32s-32-14.31-32-32V205.3L174.6 254.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l103.1-103.1C241.3 97.4 251.1 96 256 96c4.881 0 14.65 1.391 22.65 9.398l103.1 103.1C395.1 221.9 395.1 242.1 382.6 254.6z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="#fff"
                    >
                      <path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM382.6 302.6l-103.1 103.1C270.7 414.6 260.9 416 256 416c-4.881 0-14.65-1.391-22.65-9.398L129.4 302.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 306.8V128c0-17.69 14.33-32 32-32s32 14.31 32 32v178.8l49.38-49.38c12.5-12.5 32.75-12.5 45.25 0S395.1 290.1 382.6 302.6z" />
                    </svg>
                  )}
                </div>
                <p className="font-bold text-[11px] text-white">
                  {changePercent.toFixed(2)}%
                </p>
              </div>
              <p className="font-bold text-[11px] text-black">{stockArr.symbol}</p>
              {config && (
                <div
                  className={`flex w-6 h-6 mr-[-10px] ml-[6px] justify-center items-center rounded-full`}
                  onClick={() => {
                    window.open(
                      `${config.watchlistLaunchUrlPre}${stockArr.symbol}${config.watchlistLaunchUrlPost}`,
                      "_blank",
                      "toolbar=0,location=0,menubar=0,left=300px,top=50px,height=600,width=1100"
                    );
                  }}
                >
                  <img
                    className="flex-1 rounded-full"
                    src={config.stockImageURI2}
                  />
                </div>
              )}
            </div>
          </div>
        </Tooltip>
      </ThemeProvider>
    </div>
  );
}

export default NewsStockSlider;
