import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { database } from "../../firebase";
import Sidebar from "../Sidebar";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../StateProvider";
import NewsCard from "../../components/NewsCard";
import NewsStockSlider from "../../components/NewsStockSlider";

function LatestTab() {
  const [loading, setloading] = useState(true);
  const [news, setnews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [mostactive, setmostactive] = useState([]);
  const [topgainers, settopgainers] = useState([]);
  const [toplosers, settoplosers] = useState([]);
  const [spy, setspy] = useState({});
  const [dia, setdia] = useState({});
  const [bookmarknews, setbookmarknews] = useState([]);
  const [marketmovers, setmarketmovers] = useState(false);
  const [{ user, tab, chat, send, sharednews, selectedstocks, search }, dispatch] =
    useStateValue();
  const [storedsymbols, setstoredsymbols] = useState([]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    getNews();

    getMostActive();
    getTopGainers();
    getTopLosers();
    getSPY();
    getDIA();
  }, [tab, selectedstocks]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    if (user) {
      const userInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        userInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setreadnews([]);
            setbookmarknews([]);
          } else {
            let snapVal = snapshot.val();
            if(snapVal.bookmarknews !== undefined && snapVal.bookmarknews !== null){
            setreadnews(Object.keys(snapVal.readnews));
            setbookmarknews(Object.keys(snapVal.bookmarknews));
            }
          }
        },
        (err) => {
          setreadnews([]);
          setbookmarknews([]);
        }
      );
      const watchlistInfo = ref(
        database,
        `/users/${user.phoneNumber}/watchlist`
      );
      onValue(
        watchlistInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setstoredsymbols([]);
          } else {
            let snapVal = snapshot.val();
            if(snapVal !== undefined && snapVal !== null){
            setstoredsymbols(Object.keys(snapVal));
            }
          }
        },
        (err) => {
          console.log("error getting watchlist", err);
          setstoredsymbols([]);
        }
      );
    } else {
      // dispatch({
      //   type: actionTypes.SET_TAB,
      //   tab: 2,
      // });
      setreadnews([]);
      setbookmarknews([]);
    }
    getNews();
  }, [user]);

  useEffect(() => {
    if (!search.enabled){
      getNews();
    }
  }, [search.enabled]);
  
  const getMostActive = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/mostactive`)
      .then((data) => data.json())
      .then((datajson) => {
        setmostactive(datajson);
      });
  };

  const getTopGainers = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/gainers`)
      .then((data) => data.json())
      .then((datajson) => {
        settopgainers(datajson);
      });
  };

  const getTopLosers = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/losers`)
      .then((data) => data.json())
      .then((datajson) => {
        settoplosers(datajson);
      });
  };

  const getSPY = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=SPY`)
      .then((data) => data.json())
      .then((datajson) => {
        setspy(datajson);
      });
  };

  const getDIA = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=DIA`)
      .then((data) => data.json())
      .then((datajson) => {
        setdia(datajson);
      });
  };

  const getNews = () => {
    let stocks = selectedstocks.map(stock => stock.symbol).join("&ticker=");
    let url = `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/news?sort=refCountTime&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;
    url = `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/news?sort=pubTime&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        setnews(respJson);
        setloading(false);
      })
      .catch((error) => {
        setnews([]);
        setloading(false);
      });
  };

  const getHeader = () => {
    return (
      <div className="flex flex-col sticky top-0 z-50 bg-white">
        <div
          className={`flex w-full items-center justify-center items-center ${
            chat.enabled
              ? "flex-col py-6"
              : "flex-col sm:flex-row h-[120px] sm:h-auto"
          }`}
        >
          <div
            className={`flex flex-1 h-[100px] w-full sticky top-0 z-50 items-center ${
              chat.enabled
                ? "justify-center mb-[15px]"
                : "pl-0 sm:pl-4 justify-center sm:justify-start"
            }`}
          >
            <div className="w-4 h-4 mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="#0d5446"
              >
                <path d="M240.5 224H352C365.3 224 377.3 232.3 381.1 244.7C386.6 257.2 383.1 271.3 373.1 280.1L117.1 504.1C105.8 513.9 89.27 514.7 77.19 505.9C65.1 497.1 60.7 481.1 66.59 467.4L143.5 288H31.1C18.67 288 6.733 279.7 2.044 267.3C-2.645 254.8 .8944 240.7 10.93 231.9L266.9 7.918C278.2-1.92 294.7-2.669 306.8 6.114C318.9 14.9 323.3 30.87 317.4 44.61L240.5 224z" />
              </svg>
            </div>

            <h1 className="font-bold text-2xl text-black text-[#0d5446] mt-1">
              Latest News
            </h1>
          </div>
          <div
            className={`${
              user ? "flex flex-row" : "flex sm:hidden flex-row"
            } p-2 px-4 border rounded-full ${
              marketmovers ? "bg-[#f0f0f0]" : "bg-white"
            } items-center justify-center hover:bg-[#f0f0f0] ${
              chat.enabled ? "mb-[0px]" : "mr-0 sm:mr-4 mb-[20px] sm:mb-0"
            }`}
            onClick={() => setmarketmovers(!marketmovers)}
          >
            <p className="text-xs mr-2">How is market looking today?</p>
            <div className="w-2 h-2">
              {marketmovers ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                </svg>
              )}
            </div>
          </div>
        </div>
        {selectedstocks && selectedstocks.length > 0 && (
          <div className="flex px-3 pb-2 flex-wrap">
            {selectedstocks.map((stock) => {
              return (
                <div
                  className="flex flex-row justify-center items-center p-2 py-1 pl-4 pr-[8px] rounded-full border cursor-pointer hover:bg-[#e5e7eb] mr-2 mb-[5px]"
                  onClick={() => {
                    dispatch({
                      type: actionTypes.DELETE_SELECTED_STOCK,
                      stock: stock,
                    });
                    setloading(true);
                    setnews([]);
                  }}
                >
                  <p className="text-sm">{stock.name}</p>
                  <div className="w-3 h-3 flex ml-[10px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                    </svg>
                  </div>
                </div>
              );
            })}
            <div
              className="flex justify-center items-center ml-4 mb-[5px]"
              onClick={() => {
                dispatch({
                  type: actionTypes.CLEAR_SELECTED_STOCK,
                });
                setloading(true);
                setnews([]);
              }}
            >
              <p className="text-[12px]">Clear All</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderSPY = () => {
    let changePercent = (spy.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-row transition ${
          isUp ? "bg-[#1ab394]" : "bg-[#f44336]"
        } rounded-md mb-3 group mr-2 flex-1 p-4 max-w-[400px]`}
      >
        <div className="flex flex-1 flex-col justify-center items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#0d5446]" : "bg-[#82231d]"
            } rounded-[5px] px-2 py-1`}
          >
            {spy.symbol}
          </div>
          <h3 className="text-md font-semibold text-white mr-3">S & P 500</h3>
        </div>
        <div className="flex flex-1 flex-col justify-center items-end">
          <p className="font-bold text-md text-white">{spy.latestPrice}</p>
          <p className="text-xs text-white">{changePercent}%</p>
        </div>
      </li>
    );
  };

  const renderDIA = () => {
    let changePercent = (dia.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-row transition ${
          isUp ? "bg-[#1ab394]" : "bg-[#f44336]"
        } rounded-md mb-3 group mr-2 flex-1 p-4 max-w-[400px]`}
      >
        <div className="flex flex-1 flex-col justify-center items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#0d5446]" : "bg-[#82231d]"
            } rounded-[5px] px-2 py-1`}
          >
            {dia.symbol}
          </div>
          <h3 className="text-md font-semibold text-white mr-3">Dow Jones</h3>
        </div>
        <div className="flex flex-1 flex-col justify-center items-end">
          <p className="font-bold text-md text-white">{dia.latestPrice}</p>
          <p className="text-xs text-white">{changePercent}%</p>
        </div>
      </li>
    );
  };

  const renderMarketMovers = () => {
    return (
      <div
        className={`flex-col ${
          marketmovers
            ? "p-3 pb-5 pb-0 h-[400px] border-b-[5px]"
            : "h-[0px] overflow-hidden"
        } transition-height transition-slowest ease ${
          user ? "flex" : "sm:hidden"
        }`}
      >
        <div className="flex flex-row">
          {renderSPY()}
          {renderDIA()}
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="text-md font-semibold pr-8">Most Active Stocks:</h3>
          <div>
            {mostactive && mostactive.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll">
                {mostactive.map((stockArr, index) => {
                  return (
                    <NewsStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="text-md font-semibold pr-8">
            Top Gainers (intraday):
          </h3>
          <div>
            {topgainers && topgainers.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll">
                {topgainers.map((stockArr, index) => {
                  return (
                    <NewsStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="text-md font-semibold pr-8">Top Losers (intraday):</h3>
          <div>
            {toplosers && toplosers.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll">
                {toplosers.map((stockArr, index) => {
                  return (
                    <NewsStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        className={`flex flex-col flex-1 overflow-y-scroll scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
          user ? "" : "rounded-r-3xl"
        }`}
      >
        {renderMarketMovers()}
        {<Sidebar mobile={true} />}
        {getHeader()}
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex-1 scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
        user ? "" : "sm:rounded-r-3xl"
      } ${send.enabled ? "overflow-y-hidden" : "overflow-y-scroll"}`}
    >
      {renderMarketMovers()}
      {<Sidebar mobile={true} />}
      {getHeader()}
      <ul className="mt-0 relative">
        {sharednews.newsTitle && (
          <NewsCard
            key={"sharednews"}
            news={sharednews}
            bookmarknews={bookmarknews}
            readnews={readnews}
            storedsymbols={storedsymbols}
          />
        )}
        {news &&
          news.map((news, index) => {
            return (
              <NewsCard
                key={index}
                news={news}
                bookmarknews={bookmarknews}
                readnews={readnews}
                storedsymbols={storedsymbols}
              />
            );
          })}
      </ul>
    </div>
  );
}

export default LatestTab;
