import { useStateValue } from "../StateProvider";
import { actionTypes } from "../reducer";
import { useEffect, useState } from "react";
import StockDetails from "./StockDetails";
import NewsCard from "../components/NewsCard";
import TrendingTab from "./tabs/TrendingTab";

function SearchScreen() {
  const [searchText, setsearchText] = useState("");
  const [searchStocks, setSearchStocks] = useState([]);
  const [{ tab, selectedstocks}, dispatch] = useStateValue();
  const [stocks, setstocks] = useState([]);
  const [globalSearchStocks, setGlobalSearchStocks] = useState([]);
  const [news, setnews] = useState([]);
  const [loading, setloading] = useState(true);
  const [bookmarknews, setbookmarknews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [storedsymbols, setstoredsymbols] = useState([]);
  const [currentScreen, setCurrentScreen] = useState('');

  /*
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/getTickerSymbols?search=${''}`)
      .then((response) => response.json())
      .then((dataJSON) => {
        if (dataJSON) {
          //setGlobalStocks(dataJSON.slice(0, 10));
          setstocks(dataJSON);
        } else {
          setstocks([]);
        }
      })
  }, []);
  */

  const searchStock = (text) => {
    /**
      // Fetch the stock based on symbol from backend
      fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/getTickerSymbols?search=${text}`)
        .then((response) => response.json())
        .then((dataJSON) => {
          // If no stocks found via symbol, fallback to searching by name locally
          if (dataJSON.length === 0) {
            const fallbackSearch = stocks.filter(stock =>
              stock.name.toLowerCase().includes(text.toLowerCase()) // Local name search
            );
            setGlobalSearchStocks(fallbackSearch);
          } else {
            setGlobalSearchStocks(dataJSON);
          }
        })
        .catch(() => {
          // Fallback in case of network error, perform local search by name
          const fallbackSearch = stocks.filter(stock =>
            stock.name.toLowerCase().includes(text.toLowerCase()) // Local name search
          );
          setGlobalSearchStocks(fallbackSearch);
        });
    */
    let url = `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/getTickerSymbols?search=${text}`;
    fetch(url)
      .then((data) => data.json())
      .then((dataJson) => dataJson.slice(0, 5))
      .then((sliceData) => {
        setstocks(sliceData);
      })
      .catch((err) => {
        console.log("err searchStock", err);
        setstocks([]);
    })
  };

  const selectGlobalStock = (text) => {
    console.log('Selected stock', text)
    updateSearchText(text)
    console.log("Fetched News Data:", news) 
  }

  useEffect(() => {
    searchStock("a");
  }, []);

  const updateSearchText = (text) => {
    selectedstocks.push(text)
    //let stocks = selectedstocks.join("&ticker=");
    console.log('stocks', selectedstocks)
    //setSearchStocks(stocks)
    /*
    let url = `https://opiniontrade.ai/opinion/news?sort=refCountTime&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        console.log('News response:', respJson);
        setnews(respJson);
        setloading(false);
      })
      .catch((error) => {
        setnews([]);
        setloading(false);
      });
      */
  }

  return (
    <div className="w-full h-full absolute bg-[#000]/50 z-[1001] backdrop-blur-sm">
      <div className="flex justify-center items-center flex-col">
        <div className="sm:min-w-[50%] bg-white flex flex-row mt-10 items-center rounded-lg p-4 transition duration-200 focus:outline-none drop-shadow-2xl">
          <div className="w-4 h-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z" />
            </svg>
          </div>
          <input
            className="flex-1 ml-4 focus:outline-none"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setsearchText(event.target.value);
              searchStock(event.target.value);
            }}
          />
          <div
            onClick={() => {
              dispatch({
                type: actionTypes.SET_SEARCH,
                enabled: false,
              });
              setsearchText("");
            }}
            className="flex flex-row border rounded-md px-2 py-1 items-center hover:bg-[#f2f2f2] hover:cursor-pointer"
          >
            <div className="w-4 h-4">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
              </svg>
            </div>
            <p className="text-[12px]">CLOSE</p>
          </div>
        </div>
        <div className="flex flex-row mt-4 p-4">
          <div className="flex flex-1 flex-col cursor-pointer">
            {stocks.map((stock, index) => (
              <div
                key={index}
                className="rounded-md p-4 border mb-2 bg-white"
                onClick={() => {selectGlobalStock(stock); dispatch({
                  type: actionTypes.SET_SEARCH,
                  enabled: false,
                });
                setsearchText("");}}
              >
                <div className="flex flex-row items-center">
                  <div className="flex flex-1 flex-col justify-start items-start">
                    <p className="text-black font-bold">{stock.name}</p>
                    <p className="text-[10px]">
                      <span className="font-bold">{stock.symbol}</span> :{" "}
                      {stock.exchange}
                    </p>
                  </div>
                  <StockDetails stock={stock} />
                </div>
              </div>
            ))}
            {/*currentScreen === 'trending' && <TrendingTab searchStocks={searchStocks} currentScreen={currentScreen}/>*/}
            {/**searchText.length > 0 && news.length > 0 &&
              news.map((newsItem, index) => (
                <NewsCard
                key={index}
                news={newsItem}
                bookmarknews={bookmarknews}
                readnews={readnews}
                storedsymbols={storedsymbols}
              />
              ))*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchScreen;
