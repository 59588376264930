import { database } from "../firebase";
import { useStateValue } from "../StateProvider";
import { useEffect, useState } from "react";
import moment from "moment";
import { get, onValue, ref, set, update } from "firebase/database";
import ChatRow from "./ChatRow";
import { v4 as uuidv4 } from "uuid";
import { actionTypes } from "../reducer";
import countryCodeData from "../data/country_phone.json"

import {
  getNameByPhone,
  getNotifSettingByPhone,
  getPhoneListByGid,
  getTokenByPhone,
  sendPushNotification,
} from "../Utils";

function ChatGroup() {
  const [rooms, setrooms] = useState([]);
  const [contacts, setcontacts] = useState();
  const [{ user, chat, send, config }, dispatch] = useStateValue();
  const [loading, setloading] = useState(true);
  const [searchtext, setsearchtext] = useState("");

  useEffect(() => {
    const userInfo = ref(database, `/users/${user.phoneNumber}/`);
    onValue(userInfo, (snapshot) => {
      if (snapshot.exists) {
        let snapVal = snapshot.val();

        let loggedInUserCountryCodeWithPlus = '';
        let loggedInUserCountryCodeNumber = '';
        let loggedInUserCountry = '';

        for (const key in countryCodeData){
          if (user.phoneNumber.startsWith(countryCodeData[key])){
            loggedInUserCountryCodeWithPlus = countryCodeData[key];
            loggedInUserCountryCodeNumber = countryCodeData[key].replace('+', '');
            loggedInUserCountry = key;
            break;
          } 
        }

        const updatedContacts = {}
        if (snapVal.contact){
          for (let originalNumber in JSON.parse(snapVal.contact)){
            let newNumber = originalNumber;
            if (originalNumber.startsWith('00')){
              newNumber = originalNumber.replace('00', '+');
            } else if (originalNumber.startsWith('011')){
              newNumber = originalNumber.replace('011', '+');
            } else if (originalNumber.startsWith('0')) {
              newNumber = originalNumber.replace('0', loggedInUserCountryCodeWithPlus);
            } else if (originalNumber.startsWith(loggedInUserCountryCodeNumber)){
              newNumber = '+' + originalNumber;
            } else if (!originalNumber.startsWith(loggedInUserCountryCodeWithPlus)){
              newNumber = loggedInUserCountryCodeWithPlus + originalNumber;
            }
        
            updatedContacts[newNumber] = JSON.parse(snapVal.contact)[originalNumber];
          }
        }
        setcontacts(updatedContacts);
      }
    });
  }, []);

  useEffect(() => {
    const userInfo = ref(database, `/users/${user.phoneNumber}/chats`);
    onValue(userInfo, (snapshot) => {
      if (!snapshot.exists) {
        setrooms([]);
        setloading(false);
      } else {
        let snapVal = snapshot.val();
        if(snapVal){
          let myGroups = Object.values(snapVal);
          myGroups.sort(function (x, y) {
            let a = moment(x.updatedAt);
            let b = moment(y.updatedAt);
            return b - a;
          });
          setrooms(myGroups);
          setloading(false);
        }
      }
    });
  }, []);

  const searchChat = (value) => {
    setsearchtext(value);
    // setloading(true);
  };

  const postComment = async () => {
    let userEmail = "shalabh_saxena@yahoo.com";
    let ticker = send.data.refStockQuote ? send.data.refStockQuote.map(item => item.symbol).join(", ") : '';
    const stks = send.comment;
    if (stks) {
      let nstks = stks.split(/[\s,]+/).filter((e) => {
        if (e.startsWith("$")) {
          return e;
        }
      });
      if (nstks.length > 0) {
        if (ticker.length > 0){
          ticker += ',';
        }
        ticker = nstks.join(',');
      }
    }

    ticker = ticker || "NOASSOC";

    console.log('ticker:', ticker);
    var data = {
      request: {
        aisummary: send.data.aisummary,
        entityKey: ticker,
        entityUserVal: 1,
        comments: send.comment || "",
        hyperlink1: send.data.newsLink,
        userName: userEmail,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/postComment/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.status >= 200 && response.status < 300) {
      }
    } catch (errors) {
      console.log("errorsPOSTCOMMENT", errors);
    }
  };

  const sendNewsCard = () => {
    postComment();
    let roomid = send.selected;
    const userInfo = ref(database, `/chatrooms/${roomid}/`);
    let stocks = send.comment.split(/[\s,]+/).filter((e) => {
      if (e.startsWith("$")) {
        return e;
      }
    });
    let stocksjoinedstring = stocks.join("&ticker=").replace("$", "");
    let url = `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/stocksQuote?ticker=${stocksjoinedstring}`;
    dispatch({
      type: actionTypes.SEND_NEWS,
      sendenabled: false,
      news: [],
      room: [],
    });
    fetch(url)
      .then((data) => data.json())
      .then((dataJson) => {
        get(userInfo)
          .then((snapshot) => {
            if (!snapshot.exists) {
              throw "doc doesn't exist";
            } else {
              let data = snapshot.val();
              let group = data.group;
              let uuid = uuidv4();
              let messageObj = {
                _id: uuid,
                text: "OpinionTradeApp",
                createdAt: moment().utc().format(),
                type: "news",
                content: {
                  newsTitle: send.data.newsTitle,
                  newsLink: send.data.newsLink,
                  refStock: send.data.refStock || "",
                  refStockQuote: send.data.refStockQuote
                    ? [...send.data.refStockQuote, ...dataJson]
                    : [...dataJson],
                  newsSite: send.data.newsSite,
                  cmt: send.comment || "",
                  aisummary: send.data.aisummary ? send.data.aisummary : ""
                },
                user: {
                  id: user.uid,
                  _id: user.uid,
                  usernumber: '+' + user.phoneNumber.replace(/\D/g, ""),
                },
                readBy: ['+' + user.phoneNumber.replace(/\D/g, "")],
              };
              set(
                ref(database, `/chatrooms/${roomid}/messages/${uuid}/`),
                messageObj
              );

              update(ref(database, `/chatrooms/${roomid}/`), {
                lastmessage: messageObj,
                updatedAt: moment().utc().format(),
              });

              data.users.map((userPhoneNumber, index) => {
                const phoneNumber = userPhoneNumber.number;
                update(
                  ref(database, `/users/${phoneNumber}/chats/${roomid}/`),
                  {
                    updatedAt: moment().utc().format(),
                  }
                );
              });

              dispatch({
                type: actionTypes.SEND_NEWS,
                sendenabled: false,
                news: [],
                room: [],
              });

              dispatch({
                type: actionTypes.SET_SHARED_NEWS,
                news: {
                  newsTitle: "",
                  newsLink: "",
                  newsSite: "",
                },
              });
              dispatch({
                type: actionTypes.SET_COMMENT,
                comment: "",
              });

              const procPushNotification = async () => {
                let myPhone = user.phoneNumber;

                const targetPhones = await getPhoneListByGid(myPhone, roomid);
                // Extract only the numbers from targetPhones
                const phoneNumbers = targetPhones.map(phone => phone.number);
                phoneNumbers.map(async (phone, index) => {
                  let notiSettings = await getNotifSettingByPhone(phone);
                  let enableNotification = false;
                  if (targetPhones.length > 2) {
                    enableNotification = notiSettings.groupMessage;
                  } else {
                    enableNotification = notiSettings.peerMessage;
                  }

                  if (enableNotification) {
                    const theToken = await getTokenByPhone(phone);
                    const dName = await getNameByPhone(phone, myPhone);
                    theToken.map((token, index) => {
                      sendPushNotification(
                        token,
                        group === true
                          ? dName + " @ " + data.groupName
                          : dName + " shared a news card",
                        group === true
                          ? "🔗 Shared a news card: " + send.data.newsTitle
                          : "🔗 " + send.data.newsTitle,
                        ",",
                        notiSettings.sound,
                        roomid,
                        group === true ? data.groupName : dName,
                        targetPhones,
                        config.OAuthTokenForMessaging.OauthAccessToken
                      );
                    });
                  }
                });
              };

              procPushNotification();
              postComment();
            }
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
  };

  if (loading) {
    return (
      <div
        className={`pt-3 flex-1 bg-white h-full overflow-y-scroll border-r ${
          user ? (chat.enabled ? "" : "rounded-r-3xl") : ""
        } transition-width transition-slowest ease max-w-[340px]`}
      >
        <h1 className="font-bold text-2xl text-black my-5 ml-3">Chats</h1>
        {/* <label className="px-3 mt-5">
          <input
            className="rounded-lg p-4 bg-gray-100 transition duration-200 focus:outline-none focus:ring-2 w-11/12"
            placeholder="Search..."
            value={searchtext}
            onChange={(event) => {
              searchChat(event.target.value);
            }}
          />
        </label> */}
        <div
          role="status"
          className="mt-6 space-y-4 rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
        >
          <div className="flex justify-between items-center">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
        </div>
        <div
          role="status"
          className="mt-6 space-y-4 rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
        >
          <div className="flex justify-between items-center">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
          </div>
        </div>
      </div>
    );
  }

  let sendenabled = send.enabled;
  let userselected = send.selected.length > 0;
  return (
    <div
      className={`flex flex-col flex-1 bg-white h-full overflow-y-scroll scrollbar-hide border-r ${
        user ? (chat.enabled ? "" : "rounded-r-3xl") : ""
      } transition-width transition-slowest ease max-w-[340px] z-[100]`}
    >
      <div className="sticky top-0 z-50 bg-white py-3">
        <h1 className="font-bold text-2xl text-black my-5 ml-3">
          {sendenabled ? "Select Chat" : "Chats"}
        </h1>
        <label className="px-3 mt-5">
          <input
            className="rounded-lg p-4 bg-gray-100 transition duration-200 focus:outline-none focus:ring-2 w-11/12"
            placeholder="Search..."
            value={searchtext}
            onChange={(event) => {
              searchChat(event.target.value);
            }}
          />
        </label>
      </div>
      <ul className="flex-1 overflow-y-scroll">
        {rooms &&
          rooms.map((room, index) => (
            <ChatRow
              key={index}
              index={index}
              id={room.id}
              contacts={contacts || []}
              searchtext={searchtext}
            />
          ))}
      </ul>
      {send.enabled && (
        <>
          {/**<div className="">
          <input
            className="p-4 bg-gray-100 transition duration-200 focus:outline-none w-full border-t-2 border-[#1ab394]"
            placeholder="Comment"
            value={send.comment}
            onChange={(event) => {
              setcomment(event.target.value);
            }}
          />
          </div> */}
          <div
            className={`flex justify-center items-center cursor-pointer ${
              userselected ? "bg-[#1ab394]" : "bg-gray-300"
            } py-4`}
            onClick={() => {
              if (userselected) {
                sendNewsCard();
              }
            }}
          >
            <p className={userselected ? "text-white" : "text-gray-500"}>
              Send
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default ChatGroup;
