import "./styles/ChatWindow.css";
import { useStateValue } from "../StateProvider";
import moment from "moment";
import { actionTypes } from "../reducer";
import { database } from "../firebase";
import { useEffect, useState, useRef } from "react";
import { onValue, ref, set, update } from "firebase/database";
import { normPhone } from "../Utils";
import ScrollView from "react-inverted-scrollview";
import { v4 as uuidv4 } from "uuid";
import {
  getPhoneListByGid,
  getNotifSettingByPhone,
  getTokenByPhone,
  getNameByPhone,
  sendPushNotification,
} from "../Utils";
import NewsStockSlider from "../components/NewsStockSlider";
import NewsCard from "../components/NewsCard";

function ChatWindow() {
  const [message, setMessage] = useState([]);
  const [lastmessage, setLastMessage] = useState();
  const [totalmessages, setTotalMessages] = useState([]);
  const [showbutton, setShowButton] = useState(false);
  const [totalMessagesLoaded, settotalMessagesLoaded] = useState(false);
  const [loading, setloading] = useState(true);
  const [storedsymbols, setstoredsymbols] = useState([]);
  const [{ user, chat, config}, dispatch] = useStateValue();
  const [chatinput, setchatinput] = useState("");
  const [chatusers, setchatusers] = useState([]);
  const [contacts, setContacts] = useState({});
  const prevtotalMessagesLength = useRef(totalmessages.length);
  const scrollViewRef = useRef(null);
  const [group, setGroup] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (chat.data.id) {
      const messageInfo = ref(database, `/chatrooms/${chat.data.id}`);
      onValue(messageInfo, async (snapshot) => {
        if (!snapshot.exists) {
          setMessage([]);
          setloading(false);
        } else {
          let snapVal = snapshot.val();
          setData(snapVal);
          let group = snapVal.group;
          setGroup(group);
          let newMsgs = snapVal.messages ? Object.values(snapVal.messages) : [];
          let lastMsg = snapVal.lastmessage ? snapVal.lastmessage : {};
          setLastMessage(lastMsg);
          setchatusers(snapVal.users);
          if (newMsgs.length > 0) {
            newMsgs.sort(function (x, y) {
              let a = moment(x.createdAt);
              let b = moment(y.createdAt);
              return a - b;
            });
            setShowButton(newMsgs.length > 10);
            setMessage(newMsgs.slice(-10));
            setTotalMessages(newMsgs);
            setloading(false);
          } else {
            setMessage([]);
            setTotalMessages([]);
            setloading(false);
          }
        }
      });
    } else {
      setMessage([]);
      setloading(false);
    }
  }, [chat.data.id]);

  useEffect(() => {
    const userInfo = ref(database, `/users/${user.phoneNumber}/watchlist`);
    onValue(
      userInfo,
      (snapshot) => {
        if (!snapshot.exists) {
          setstoredsymbols([]);
        } else {
          let snapVal = snapshot.val();
          if (snapVal){
          setstoredsymbols(Object.keys(snapVal));
          }
        }
      },
      (err) => {
        console.log("error getting watchlist", err);
        setstoredsymbols([]);
      }
    );
    const userContacts = ref(database, `/users/${user.phoneNumber}`);
    onValue(
      userContacts,
      (snapshot) => {
        if (!snapshot.exists) {
          setContacts({});
        } else {
          let snapVal = snapshot.val();
          if (snapVal){
            setContacts(JSON.parse(snapVal.contact));
          }
        }
      }
    )
  }, []);

  const loadEarlierMessages = async () => {
    //const combinedMessages = []
    if (showbutton){
      const firstMatch = totalmessages.find(item => message.includes(item));
      if (firstMatch){
        const indexOfFirstMatch = totalmessages.indexOf(firstMatch);
        const numberOfItemsBefore = 5; 

    
        const startIndex = Math.max(0, indexOfFirstMatch - numberOfItemsBefore);
        const earlierMessagesArray = totalmessages.slice(
          startIndex,
          indexOfFirstMatch
        );
        const combinedMessages = [...earlierMessagesArray, ...message]
        setMessage(combinedMessages);
        setShowButton(combinedMessages.length < totalmessages.length)
      }else{
        console.log('no match found');
      }
    } 

    const scrollContainer = document.querySelector('inverted');
    if (scrollContainer){
      scrollContainer.classList.add('scroll-to-top');
    }
  }

  const sendmessage = () => {
    let roomid = chat.data.id;
    if (chatinput) {
      let uuid = uuidv4();
      let messageArr = {
        _id: uuid,
        text: chatinput,
        createdAt: moment().utc().format(),
        type: "text",
        user: {
          id: user.uid,
          _id: user.uid,
          usernumber: '+' + user.phoneNumber.replace(/\D/g, ""),
        },
        readBy: ['+' + user.phoneNumber.replace(/\D/g, "")],
      };

      set(ref(database, `/chatrooms/${roomid}/messages/${uuid}/`), messageArr);

      update(ref(database, `/chatrooms/${roomid}/`), {
        lastmessage: messageArr,
        updatedAt: moment().utc().format(),
      });

      chat.data.users.map((userPhoneNumber, index) => {
        const phoneNumber = userPhoneNumber.number;
        update(ref(database, `/users/${phoneNumber}/chats/${roomid}/`), {
          updatedAt: moment().utc().format(),
        });
      });
      setchatinput("");
      const procPushNotification = async () => {
        let myPhone = user.phoneNumber;

        const targetPhones = await getPhoneListByGid(myPhone, roomid);

        // Extract only the numbers from targetPhones
        const phoneNumbers = targetPhones.map(phone => phone.number);
        phoneNumbers.map(async (phone, index) => {
          let notiSettings = await getNotifSettingByPhone(phone);

          let enableNotification = false;
          if (targetPhones.length > 2) {
            enableNotification = notiSettings.groupMessage;
          } else {
            enableNotification = notiSettings.peerMessage;
          }

          if (enableNotification) {
            const theToken = await getTokenByPhone(phone);
            const dName = await getNameByPhone(phone, myPhone);
            theToken.map((token, index) => {
              // send push notification
              sendPushNotification(
                token,
                group === true
                ? dName + " @ " + data.groupName
                : 'From: ' + dName,
                chatinput,
                ",",
                notiSettings.sound,
                roomid,
                dName,
                targetPhones,
                config.OAuthTokenForMessaging.OauthAccessToken
              );
            });
          }
        });
      };

      procPushNotification();
    }
  };

  const getDomain = (link) => {
    const { hostname } = new URL(link);
    return hostname.slice(4, hostname.length);
  };

  return (
    <div className="flex-1 bg-white rounded-r-3xl relative flex flex-col w-[380px] max-w-[380px]">
      <div className="flex justify-evenly items-center h-20 border-b py-3 px-4">
        <div className="flex flex-1">
          <div
            className="h-12 w-4 rounded-full overflow-hidden realtive flex justify-center py-3 cursor-pointer"
            onClick={() =>
              dispatch({
                type: actionTypes.SET_CHAT,
                chatenabled: false,
                chatdata: [],
              })
            }
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
            </svg>
          </div>
          <div className="h-12 w-12 rounded-full overflow-hidden mx-3">
            <img
              src={chat.data.thumbnail}
              loading="lazy"
              className="h-full w-full object-cover"
              onError={(e) =>
                (e.currentTarget.src =
                  "https://cdn-icons-png.flaticon.com/512/149/149071.png")
              }
            />
          </div>
          <div className="flex flex-1 flex-col">
            <h3 className="font-semibold text-lg">{chat.data.name}</h3>
            <p className="text-light text-gray-400 text-xs">
              {chat.data.lastMessage
                ? moment(chat.data.lastMessage.createdAt).local().fromNow()
                : moment(chat.data.updatedAt).local().fromNow()}
            </p>
          </div>
        </div>
      </div>
      <ScrollView ref={scrollViewRef} width="100%" height="100%">
        <div className="flex-1 flex-col overflow-y-scroll inverted pt-4 px-4">
          {loading && (
            <div role="status" className="flex justify-center m-10">
              <svg
                aria-hidden="true"
                className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
         { showbutton && (
         <button style={{ position: 'absolute', top: 75, left: '50%', transform: 'translateX(-50%)', zIndex: 1, backgroundColor: "lightgrey", borderRadius: '50px', width: '200px'}} 
                 onClick={loadEarlierMessages}
          > Load Earlier Messages
          </button> 
          )}
          {message &&
            message.map((msg) => {
              if (msg) {
                let isCurrentUser =
                  msg.user.usernumber === normPhone(user.phoneNumber);
                console.log('iscurrent user', isCurrentUser);
                const isAllRead =
                  JSON.stringify(chatusers.sort()) ===
                  JSON.stringify(msg.readBy.sort());

                let messageKeys = chat.data.messages
                  ? Object.keys(chat.data.messages)
                  : [];

                let readBy = msg.readBy;
                let newReadBy = [...readBy, normPhone(user.phoneNumber)];
                if (
                  !readBy.includes(normPhone(user.phoneNumber)) &&
                  messageKeys.includes(msg._id)
                ) {
                  update(
                    ref(
                      database,
                      `/chatrooms/${chat.data.id}/messages/${msg._id}/`
                    ),
                    { readBy: newReadBy }
                  );
                }

                if (msg.content) {
                  return (
                    <div
                      className="flex relative justify-start items-end mb-3"
                      key={msg._id}
                    >
                      <div
                        className={`relative rounded-[5px] w-[80%] ${
                          isCurrentUser ? "ml-auto text-white"  : ""
                        }`}
                      >
                        <div
                          className={`relative p-[5px] rounded-[5px] ${
                            isCurrentUser
                              ? "ml-auto text-white border-0 bg-[#1ab394] rounded-tr-none"
                              : "bg-[#f2f2f2] rounded-tl-none"
                          }`}
                        >
                        <NewsCard
                          key={msg._id}
                          news={msg.content}
                          chat={true}
                          bookmarknews={[]}
                          readnews={[]}
                          storedsymbols={storedsymbols}
                        />
                        {msg.content.cmt.trim() !== "" ? (
                          <input
                          className="p-4 rounded-b-[10px] bg-[#1ab394] focus:outline-none w-full text-white placeholder:text-white"
                          value={msg.content.cmt}
                          readOnly
                        />
                        ) : null}
                        </div>
                        <div
                          className={`flex ${
                            isCurrentUser ? "justify-end" : "justify-start" 
                          } items-center`}
                        >
                          <span className="text-[8px] text-gray-400">
                            {moment(msg.createdAt).local().fromNow()}
                          </span>
                          {isCurrentUser &&
                            msg.lastmessage?.type !== "info-groupleft" &&
                            msg.lastmessage?.type !== "info-groupadd" && (
                              <div className="w-4 h-4 flex justify-center items-center ml-[3px]">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill={isAllRead ? "#34B7F1" : "#9fa6b2"}
                                  className="bi bi-check-all"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                </svg>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                } else if (msg.type === 'info-groupleft') {
                  return (
                    <div
                    style={{
                      maxWidth: '300px', // Adjust the width as needed
                      margin: '15px auto 30px auto', // Centered and adjusted margins
                      padding: '10px 20px',
                      borderRadius: '15px', // Fully rounded corners
                      backgroundColor: '#707070',
                      textAlign: 'center' // Center the text
                    }}
                  >
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Poppins, sans-serif',
                      }}
                    >
                      {JSON.parse(contacts[msg.user.usernumber]).givenName}{' '} left the group
                    </p>
                    </div>
                  )
                } else if (msg.type === 'info-groupadd') {
                  return (
                    <div
                    style={{
                      maxWidth: '300px', // Adjust the width as needed
                      margin: '15px auto 30px auto', // Centered and adjusted margins
                      padding: '10px 20px',
                      borderRadius: '15px', // Fully rounded corners
                      backgroundColor: '#707070',
                      textAlign: 'center' // Center the text
                    }}
                  >
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Poppins, sans-serif',
                      }}
                    >
                      {JSON.parse(contacts[msg.user.usernumber]).givenName}{' '} added to the group
                    </p>
                    </div>
                  )
                } else if (msg.type === 'info-groupremove') {
                  return (
                    <div
                    style={{
                      maxWidth: '300px', // Adjust the width as needed
                      margin: '15px auto 30px auto', // Centered and adjusted margins
                      padding: '10px 20px',
                      borderRadius: '15px', // Fully rounded corners
                      backgroundColor: '#707070',
                      textAlign: 'center' // Center the text
                    }}
                  >
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                        fontFamily: 'Poppins, sans-serif',
                      }}
                    >
                      {JSON.parse(contacts[msg.user.usernumber]).givenName}{' '} was removed from the group
                    </p>
                    </div>
                  )
                } else {
                  return (
                    <div
                      className={`flex flex-col ${
                        isCurrentUser ? "items-end" : "items-start"
                      } mb-3`}
                      key={msg._id}
                    >
                      <div className="max-w-[80%]">
                        <div
                          className={`${
                            isCurrentUser
                              ? "bg-[#1ab394] text-white rounded-tr-none"
                              : "bg-[#f2f2f2] rounded-tl-none" 
                          } p-2 rounded-[5px]`}
                        >
                          <p className="text-[14px]">{msg.text}</p>
                        </div>
                        <div
                          className={`flex ${
                            isCurrentUser ? "justify-end" : "justify-start"
                          } items-center`}
                        >
                          <span className="text-[8px] text-gray-400">
                            {moment(msg.createdAt).local().fromNow()}
                          </span>
                          {isCurrentUser &&
                            msg.lastmessage?.type !== "info-groupleft" &&
                            msg.lastmessage?.type !== "info-groupadd" && (
                              <div className="w-4 h-4 flex justify-center items-center ml-[3px]">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill={isAllRead ? "#34B7F1" : "#9fa6b2"}
                                  className="bi bi-check-all"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                </svg>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            })}
        </div>
      </ScrollView>
      {lastmessage && lastmessage.type !== "info-groupleft" ? (<div className="px-4">
        <div className="border rounded-xl bg-[#1bb394] mb-3 flex flex-col p-[5px] pb-0">
          <textarea
            className="w-full bg-gray-50 p-2 rounded-[0.6em]"
            placeholder="Type your reply here..."
            rows="3"
            value={chatinput}
            onChange={(event) => setchatinput(event.target.value)}
          ></textarea>
          <div className="flex items-center justify-end p-2 b-0 pr-0">
            <button
              className="bg-[#1ab394] text-white px-6 py-2 rounded-xl bg-[#0d5446]"
              onClick={() => sendmessage()}
            >
              Send
            </button>
          </div>
        </div>
      </div>) : (
        <div className="border rounded-xl bg-gray-50 mb-3 p-4 text-gray-500">
        The user has left the group or the group has been added. You can't message anymore. Please initiate another chat.
      </div>
      )}
    </div>
  );
}

export default ChatWindow;
