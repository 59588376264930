import { useEffect, useState } from "react";

function StockDetails({ stock = null }) {
  const [stockdetails, setstockdetails] = useState(null);
  function getStockDetails(symbol) {
    try {
      fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/stocksQuote?ticker=${symbol}`)
        .then((resp2) => resp2.json())
        .then((resp2Json) => {
          setstockdetails(resp2Json[0]);
        });
    } catch (error) {
      setstockdetails(null);
    }
  }
  useEffect(() => {
    if (stock) {
      getStockDetails(stock.symbol);
    }
  }, [stock]);
  if (stockdetails) {
    let changePercent = (stockdetails.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <div className={`ml-4 flex flex-row items-center`}>
        <p className="text-[14px]">${stockdetails.latestPrice}</p>
        <div
          className={`ml-4 ${
            isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
          } rounded-md px-2 py-1 flex flex-row items-center`}
        >
          <div className="w-[8px] h-[8px] mb-[2px] mr-1">
            {isUp ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="#137333"
              >
                <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="#a50e0f"
              >
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            )}
          </div>
          <p className={`text-[14px] ${isUp ? 'text-[#137333]' : 'text-[#a50e0f]'} font-bold`}>
            {isUp
              ? changePercent
              : changePercent.slice(1, changePercent.length - 1)}
            %
          </p>
        </div>
      </div>
    );
  }
}

export default StockDetails;
