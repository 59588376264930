import { get, ref } from "firebase/database";
import { database } from "./firebase";
import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js';

export function normPhone(phone) {
  if (phone === undefined || null){
    phone= '';
  }

  const international_prefixes = [
    "+", "00",  // Most common
    "011",  // North America (US, Canada, etc.)
    "0011",  // Australia (AU)
  
    "010",  // Japan (JP)
    "001",  // Indonesia (ID)
  
    "810",  // Russia (RU), CIS countries
    "020",  // Belarus (BY)
    "014",  // Brazil (BR)
    "016",  // New Zealand (NZ)
    "019",  // South Korea (KR)
    "009",  // Pakistan (PK), Nepal (NP), Bhutan (BT)
    "8~10",  // Armenia (AM), Azerbaijan (AZ), Georgia (GE), Kazakhstan (KZ), Tajikistan (TJ), Uzbekistan (UZ)
    "00",  // India (IN) UAE (AE)
    // Add more prefixes as needed
  ];

  const parsedPhoneNumber = parsePhoneNumber(phone);
  const countryCode = parsedPhoneNumber.country;

  for (let prefix of international_prefixes) {
    let adjustedNumber = phone;
    if (phone.startsWith(prefix)) {
      adjustedNumber = "+" + phone.slice(prefix.length);
    }
    //console.log('adjusted Number', adjustedNumber);
    const parsedNumber = parsePhoneNumberFromString(adjustedNumber, countryCode);

    //isValid() is from libphonenumber-js library checks the validity of the phonenumber according to the specified formatting rules. 
    // However, it's worth noting that some phone numbers used in our testing environment may not be valid for texting or calling purposes.
    // there we've temporarily placed it in comments. 
    if (parsedNumber /*&& parsedNumber.isValid()*/) {
      //console.log('parsed Number', parsedNumber.formatInternational().replace(/\s+/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, ''));
      return parsedNumber.formatInternational().replace(/\s+/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '');
    }
  }
}

export async function getPhoneListByGid(myPhone, gId) {
  const userInfo = ref(database, `/chatrooms/${gId}/`);
  const data = get(userInfo)
    .then((snapshot) => {
      if (!snapshot.exists) {
        throw "doc doesn't exist";
      } else {
        let data = snapshot.val();
        const phoneList = data.users.filter((item) => {
          if (item != myPhone) {
            return item;
          }
        });
        return phoneList;
      }
    })
    .catch((error) => {
      console.log("getPhoneListByGid failed:", error);
      return [];
    });

  return data;
}

export async function getNotifSettingByPhone(phone) {
  const userInfo = ref(database, `/users/${phone}/`);
  const notiSettings = get(userInfo)
    .then((snapshot) => {
      const notifSetting = {
        groupMessage: true,
        groupNewsCard: true,
        peerMessage: true,
        peerNewsCard: true,
        sound: "default",
      };
      if (!snapshot.exists) {
        console.log("No matching notification settings.");
        return notifSetting;
      } else {
        const snapVal = snapshot.val();
        const sd = snapVal.sound;
        if (typeof sd === "undefined" || sd) {
          notifSetting.sound = "default";
        } else {
          notifSetting.sound = "None";
        }

        if (typeof snapVal.groupMessage !== "undefined") {
          notifSetting.groupMessage = snapVal.groupMessage;
        }
        if (typeof snapVal.groupNewsCard !== "undefined") {
          notifSetting.groupNewsCard = snapVal.groupNewsCard;
        }
        if (typeof snapVal.peerMessage !== "undefined") {
          notifSetting.peerMessage = snapVal.peerMessage;
        }
        if (typeof snapVal.peerNewsCard !== "undefined") {
          notifSetting.peerNewsCard = snapVal.peerNewsCard;
        }

        console.log("getSoundByPhone: notifSetting:", notifSetting);
        return notifSetting;
      }
    })
    .catch((err) => {
      console.log("Error getting documents", err);
      return {
        groupMessage: true,
        groupNewsCard: true,
        peerMessage: true,
        peerNewsCard: true,
        sound: "default",
      };
    });

  console.log("getSoundByPhone: the sound:", notiSettings);

  if (typeof notiSettings === "undefined") {
    return {
      groupMessage: true,
      groupNewsCard: true,
      peerMessage: true,
      peerNewsCard: true,
      sound: "default",
    };
  } else {
    return notiSettings;
  }
}

export async function getTokenByPhone(phone) {
  const userInfo = ref(database, `/users/${phone}/token/`);
  const theToken = get(userInfo)
    .then((snapshot) => {
      if (!snapshot.exists) {
        console.log("No matching documents.");
        return [];
      } else {
        let res = [];
        let snapVal = snapshot.val() 
        console.log('snap type', snapVal);
        Object.keys(snapVal).forEach(key => {
          const device = snapVal[key];
          res.push(device.token);
        })
        return res;
      }
    })
    .catch((err) => {
      console.log("Error getting documents token", err);
      return [];
    });

  //console.log("getTokenByPhone: the token:", theToken);
  return theToken;
}

export async function getNameByPhone(phone, myPhone) {
  const userInfo = ref(database, `/users/${phone}/`);
  const dName = get(userInfo)
    .then((snapshot) => {
      if (!snapshot.exists) {
        console.log("No matching documents.");
        return [];
      } else {
        let res = myPhone;
        let snapVal = snapshot.val();
        const cStr = snapVal.contact;
        if (cStr) {
          const pName = JSON.parse(cStr);
          if (pName) {
            if (pName[myPhone]) {
              let temp = JSON.parse(pName[myPhone]);
              res = `${temp.givenName ? temp.givenName : ""}${
                temp.middleName ? " " + temp.middleName : ""
              }${temp.familyName ? " " + temp.familyName : ""}`;
            }
          }
        }

        console.log("getNameByPhone: res:", res);
        return res;
      }
    })
    .catch((err) => {
      console.log("Error getting documents", err);
      return myPhone;
    });

  console.log("getNameByPhone: dName:", dName);
  return dName;
}

export async function sendPushNotification(
  token,
  title = this.state.title,
  body = this.state.body,
  message,
  enableSound,
  chatid = null,
  chatname = null,
  phoneNumbers = [],
  oAuthToken
) {
  try {
    const phoneNumbersString = Array.isArray(phoneNumbers) ? phoneNumbers.join(',') : phoneNumbers;
    const payload = {
      message: {
        token: token, // Device token
        notification: {
          title: title,
          body: body
        },
        android: {
          notification: {
            sound: enableSound === 'default' ? 'default' : ''
          }
        },
        apns: {
          payload: {
            aps: {
              sound: enableSound === 'default' ? 'default' : ''
            }
          }
        },
        data: {
          message,
          chatid,
          chatname,
          phoneNumbers: phoneNumbersString
        }
      }
    };

    const response = await fetch('https://fcm.googleapis.com/v1/projects/chatapp-888/messages:send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${oAuthToken}`
      },
      body: JSON.stringify(payload)
    });

    // Print the response to debug
    const result = await response.json();
    console.log('Response from FCM:', result);
    return result;
  } catch (error) {
    console.error('Error sending notification:', error);
  }
}

export async function postComment(link, cmt, refStock = []) {
  let userEmail = "shalabh_saxena@yahoo.com";
  console.log("user email:" + userEmail);

  let ticker = refStock || "NOASSOC";
  const stks = cmt;
  if (stks) {
    let nstks = stks.split(/[\s,]+/).filter((e) => {
      if (e.startsWith("$")) {
        return e;
      }
    });
    if (nstks) {
      ticker = nstks.join();
    }
  }

  if (ticker.length === 0) {
    ticker = "NOASSOC";
  }

  console.log("ticker:", ticker);

  var data = {
    request: {
      entityKey: ticker,
      entityUserVal: 1,
      comments: cmt || "",
      hyperlink1: link,
      userName: userEmail,
    },
  };

  console.log("post data:", JSON.stringify(data));

  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/postComment/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.status >= 200 && response.status < 300) {
      console.log("successfully!", response);
    }
  } catch (errors) {
    console.log(errors);
  }
}