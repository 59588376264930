import React, { useEffect, useState } from "react";
import "./styles/AnimatedGradientView.css"; // Assuming you have a CSS file for styling
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";

const AnimatedGradientView = ({
  enabled,
  text = "",
  children,
  loading,
  sendSelectedNews,
  isChat = false,
}) => {
  const [{ send }, dispatch] = useStateValue();

  if (!enabled)
    return (
      <div>
        {children}
        {sendSelectedNews && !isChat && (
          <div className="relative z-[100] w-full px-2">
            <input
              className="p-4 rounded-b-[10px] bg-[#1ab394] focus:outline-none w-full text-white placeholder:text-white"
              placeholder="Comment"
              value={send.comment}
              onChange={(event) => {
                dispatch({
                  type: actionTypes.SET_COMMENT,
                  comment: event.target.value,
                });
              }}
            />
          </div>
        )}
      </div>
    );

  let gradientStyle = {
    background: "linear-gradient(to right, rgb(27 179 148), rgb(85 96 128))",
  };

  return (
    <div
      style={{
        ...gradientStyle,
        padding: 0,
        borderRadius: 10,
        marginTop: isChat ? 0 : 5,
        marginBottom: isChat ? 0 : 5,
        marginRight: isChat ? 0 : 5,
      }}
      className={`relative ${sendSelectedNews ? "z-[100]" : ""}`}
    >
      {children}
      
      <div className="App rounded-b-[10px]" style={gradientStyle}>
        <div
          className={`flex flex-1 flex-col justify-start items-start p-4 bg-[rgba(255,255,255,0.65)] rounded-b-[10px] backdrop-blur-md ${
            sendSelectedNews ? "z-[100px]" : ""
          }`}
        >
          <div className="flex flex-row justify-center items-center bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full mb-2">
            <div className="pl-2 pr-1 py-1">
              <p className="text-[10px] font-bold">OPINIONTRADE</p>
            </div>
            <div className="pr-2">
              <p className="text-[14px] font-bold italic">AI+</p>
            </div>
          </div>
          {/* <p className="text-[20px] font-bold text-black">Summary</p> */}
          {loading ? (
            <div
              role="status"
              className="rounded animate-pulse dark:divide-gray-700 mt-2"
            >
              <div className="flex justify-between items-center">
                <div>
                  <div
                    className={`h-2.5 bg-gray-100 rounded-full dark:bg-gray-400 ${
                      isChat ? "sm:w-[200px]" : "sm:w-[500px]"
                    } w-[300px] mb-2.5`}
                  ></div>
                  <div
                    className={`h-2.5 bg-gray-100 rounded-full dark:bg-gray-400 ${
                      isChat ? "sm:w-[200px]" : "sm:w-[500px]"
                    } w-[300px] mb-2.5`}
                  ></div>
                  <div
                    className={`h-2.5 bg-gray-100 rounded-full dark:bg-gray-400 ${
                      isChat ? "sm:w-[200px]" : "sm:w-[500px]"
                    } w-[300px] mb-2.5`}
                  ></div>
                  <div
                    className={`h-2.5 bg-gray-100 rounded-full dark:bg-gray-400 ${
                      isChat ? "sm:w-[200px]" : "sm:w-[500px]"
                    } w-[300px] mb-2.5`}
                  ></div>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-[14px] text-left text-black font-mono">{text}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnimatedGradientView;
