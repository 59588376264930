import { database } from "../firebase";
import { useStateValue } from "../StateProvider";
import { useEffect, useState } from "react";
import moment from "moment";
import { actionTypes } from "../reducer";
import { onValue, ref } from "firebase/database";
import { normPhone } from "../Utils";

function ChatRow({ id, contacts, searchtext }) {
  const [, setavatars] = useState("");
  const [chatdata, setchatdata] = useState({});
  const [loading, setloading] = useState(true);
  const [{ user, chat, send }, dispatch] = useStateValue();
  const [display, setdisplay] = useState(true);

  console.log('server address', process.env.REACT_APP_SERVER_ADDRESS);
  console.log('chat thumbnail', chatdata.thumbnail);
  useEffect(() => {
    setavatars(Math.floor(Math.random() * 5000));
    let myPhone = normPhone(user.phoneNumber);
    let usersToFilter = [];
    const chatInfo = ref(database, `/chatrooms/${id}/`);
    onValue(chatInfo, async (snapshot2) => {
      let itemData = snapshot2.val();
      if (itemData) {
        const groupName = itemData.groupName;
        if (itemData.public === true) {
          setchatdata({
            ...itemData,
            name: groupName,
            id: id,
          });
          setloading(false);
        } else {
          let tCount = [];
          if (itemData.messages !== undefined) {
            tCount = Object.values(itemData.messages).filter((message) => {
              return !message.readBy.includes(myPhone);
            });
          }
          let gName = groupName;
          if (gName === "_NA") {
            const tPhone = itemData.users
              .filter((item) => {
                return !item.number.includes(myPhone);
              })
              .reduce((item) => {
                return item;
              });
            usersToFilter.push(tPhone);
            if (tPhone) {
              gName = tPhone;
              let country = myPhone.slice(0, 2);
              let tName = "";
              if (contacts[tPhone.number]) {
                tName = JSON.parse(contacts[tPhone.number]);
              }
              if (tName) {
                gName = `${tName.givenName ? " " + tName.givenName : ""}${
                  tName.middleName ? " " + tName.middleName : ""
                }${tName.familyName ? " " + tName.familyName : ""}`;
              } else {
                gName = `${tPhone.number}`;
              }
            }
            setchatdata({
              ...itemData,
              name: gName,
              id: id,
              thumbnail: `${process.env.REACT_APP_SERVER_ADDRESS}:5002/images/${tPhone.number.replace('+', '')}`,
              count: tCount.length,
            });
            setloading(false);
          } else {
            if (
              itemData.messages &&
              Object.values(itemData.messages).length > 0
            ) {
              const name =
                contacts[itemData.lastmessage.text.replace(/\D/g, "")] ||
                itemData.lastmessage.text.replace(/\D/g, "");
              if (itemData.lastmessage.type === "info-groupadd") {
                setchatdata({
                  ...itemData,
                  name: gName,
                  id: id,
                  users: itemData.users,
                  thumbnail: `${process.env.REACT_APP_SERVER_ADDRESS}:5002/images/${itemData.users[0]}`,
                  count: tCount.length,
                  infomesssage: `${name} added to the group`,
                });
                setloading(false);
              } else if (itemData.lastmessage.type === "info-groupleft") {
                setchatdata({
                  ...itemData,
                  name: gName,
                  id: id,
                  users: itemData.users,
                  thumbnail: `${process.env.REACT_APP_SERVER_ADDRESS}:5002/images/${itemData.users[0]}`,
                  count: tCount.length,
                  infomesssage: `${name} left the group`,
                });
                setloading(false);
              } else {
                setchatdata({
                  ...itemData,
                  name: gName,
                  id: id,
                  users: itemData.users,
                  thumbnail: `${process.env.REACT_APP_SERVER_ADDRESS}:5002/images/${itemData.users[0]}`,
                  count: tCount.length,
                });
                setloading(false);
              }
            } else {
              setchatdata({
                ...itemData,
                name: gName,
                id: id,
                users: itemData.users,
                thumbnail: `${process.env.REACT_APP_SERVER_ADDRESS}:5002/images/${itemData.users[0]}`,
                count: tCount.length,
              });
              setloading(false);
            }
          }
        }
      }
    });
  }, [id]);

  useEffect(() => {
    if (chatdata.name) {
      if (chatdata.name.toLowerCase().includes(searchtext.toLowerCase())) {
        setdisplay(true);
      } else {
        setdisplay(false);
      }
    }
  }, [searchtext]);

  if (loading) {
    return (
      <div
        role="status"
        className="space-y-4 rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex justify-between items-center">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
      </div>
    );
  }

  if (display) {
    let isCurrentUsersMessage = chatdata.lastmessage
      ? chatdata.lastmessage.user._id === user.uid
      : false;

    let isAllRead = false;
    if (chatdata.messages && Object.values(chatdata.messages).length > 0) {
      let newMsgs = Object.values(chatdata.messages);
      newMsgs.sort(function (x, y) {
        let a = moment(x.createdAt);
        let b = moment(y.createdAt);
        return b - a;
      });
      if (
        JSON.stringify(chatdata.users.sort()) ===
        JSON.stringify(newMsgs[0].readBy.sort())
      ) {
        isAllRead = true;
      } else {
        isAllRead = false;
      }
    }
    return (
      <li
        className={`flex flex-row py-5 border-b px-3 transition-all duration-200 ${
          chat.enabled
            ? chat.data.id === id
              ? "bg-[#d0f5ed]"
              : "bg-white"
            : "bg-white"
        } hover:bg-[#d0f5ed] cursor-pointer relative items-center`}
        onClick={() => {
          if (send.enabled) {
            dispatch({
              type: actionTypes.SEND_NEWS,
              sendenabled: true,
              news: send.data,
              room: id,
            });
          } else {
            dispatch({
              type: actionTypes.SET_NAME,
              name: chatdata.name,
            });
            dispatch({
              type: actionTypes.SET_THUMBNAIL,
              thumbnail: chatdata.thumbnail,
            });
            dispatch({
              type: actionTypes.SET_CHAT,
              chatenabled: true,
              chatdata,
            });
          }
        }}
      >
        {send.enabled && (
          <input
            type="radio"
            checked={id === send.selected}
            className="w-4 h-4 mr-2"
            onChange={() => {}}
          ></input>
        )}
        <div className="h-12 w-12 rounded-full overflow-hidden mr-3">
          <img
            src={chatdata.thumbnail}
            loading="lazy"
            className="h-full w-full object-cover"
            onError={(e) =>
              (e.currentTarget.src =
                "https://cdn-icons-png.flaticon.com/512/149/149071.png")
            }
          />
        </div>
        <div className="flex-col flex-1 flex">
          <p className="text-[10px] text-gray-400 absolute top-2 right-2">
            {chatdata.lastmessage
              ? moment(chatdata.lastmessage.createdAt).local().fromNow()
              : moment(chatdata.updatedAt).local().fromNow()}
          </p>
          <div className="flex flex-1 justify-start items-center">
            {chatdata.count && chatdata.count !== 0 ? (
              <div className="flex bg-[#1ab394] rounded-[20px] w-[20px] h-[20px] justify-center items-center mr-1">
                <p className="text-[10px] text-white">{chatdata.count}</p>
              </div>
            ) : null}
            <div className="overflow-hidden text-ellipsis">
              <div className="-webkit-box truncate line-clamp-1">
                <h3 className="text-lg font-semibold">{chatdata.name}</h3>
              </div>
            </div>
          </div>
          <div className="flex flex-row text-sm text-gray-400 items-center">
            <div className="flex items-center">
              {isCurrentUsersMessage &&
                chatdata.lastmessage.type !== "info-groupleft" &&
                chatdata.lastmessage.type !== "info-groupadd" && (
                  <div className="inline-block w-5 h-5 justify-center items-center ml-[-5px] mr-1 flex-none ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill={isAllRead ? "#34B7F1" : "#9fa6b2"}
                      className="bi bi-check-all"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                    </svg>
                  </div>
                )}
              {chatdata.lastmessage?.content ? (
                <>
                  <div
                    className={`flex flex-row items-center py-[3px] rounded-[5px] px-[6px] mr-[5px] ${
                      isCurrentUsersMessage ? "bg-[#1ab394]" : "bg-[#f2f2f2]"
                    } border`}
                  >
                    <div className="w-4 h-4 flex justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill={isCurrentUsersMessage ? "#fff" : "#000"}
                      >
                        <path d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z" />
                      </svg>
                    </div>
                    <p
                      className={`${
                        isCurrentUsersMessage ? "text-white" : "text-black"
                      } text-[10px] ml-2`}
                    >
                      Shared News
                    </p>
                  </div>
                  <div className="overflow-hidden text-ellipsis">
                    <div className="-webkit-box truncate line-clamp-1">
                      <p className="text-sm text-gray-400">
                        {chatdata.lastmessage?.content?.cmt.substring(0, 12)}
                        {chatdata.lastmessage?.content?.cmt.length > 12
                          ? "..."
                          : ""}
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="overflow-hidden text-ellipsis">
                <div className="-webkit-box truncate line-clamp-1">
                  <p className="text-sm text-gray-400">
                    {chatdata.lastmessage?.text === "OpinionTradeApp"
                      ? ""
                       : chatdata.lastmessage?.type === "info-groupleft" ? JSON.parse(contacts[chatdata.lastmessage.user.usernumber]).givenName + " left the group"
                        : chatdata.lastmessage?.type === "info-groupadd" ? JSON.parse(contacts[chatdata.lastmessage.user.usernumber]).givenName + " added to the group"
                        : chatdata.lastmessage?.type === "info-groupremove" ? JSON.parse(contacts[chatdata.lastmessage.user.usernumber]).givenName + " was removed from the group"
                        : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  } else {
    return null;
  }
}

export default ChatRow;
