import { onValue, ref } from "firebase/database";
import moment from "moment";
import { useEffect, useState } from "react";
import { database } from "../../firebase";
import Sidebar from "../Sidebar";
import { useStateValue } from "../../StateProvider";
import NewsCard from "../../components/NewsCard";
import NewsStockSlider from "../../components/NewsStockSlider";

function BookmarkTab() {
  const [loading, setloading] = useState(true);
  const [news, setnews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [mostactive, setmostactive] = useState([]);
  const [topgainers, settopgainers] = useState([]);
  const [toplosers, settoplosers] = useState([]);
  const [spy, setspy] = useState({});
  const [dia, setdia] = useState({});
  const [bookmarknews, setbookmarknews] = useState([]);
  const [marketmovers, setmarketmovers] = useState(false);
  const [{ user, tab, chat, send, sharednews, selectedstocks }] =
    useStateValue();
  const [storedsymbols, setstoredsymbols] = useState([]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    getNews();

    getMostActive();
    getTopGainers();
    getTopLosers();
    getSPY();
    getDIA();
  }, [tab, selectedstocks]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    if (user) {
      const userInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        userInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setreadnews([]);
            setbookmarknews([]);
          } else {
            let snapVal = snapshot.val();
            if (snapVal.bookmarknews !== undefined && snapVal.bookmarknews !== null){
            setreadnews(Object.keys(snapVal.readnews));
            setbookmarknews(Object.keys(snapVal.bookmarknews));
            }
          }
        },
        (err) => {
          setreadnews([]);
          setbookmarknews([]);
        }
      );
      const watchlistInfo = ref(
        database,
        `/users/${user.phoneNumber}/watchlist`
      );
      onValue(
        watchlistInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setstoredsymbols([]);
          } else {
            let snapVal = snapshot.val();
            if(snapVal !== undefined && snapVal !== null)
            setstoredsymbols(Object.keys(snapVal));
          }
        },
        (err) => {
          console.log("error getting watchlist", err);
          setstoredsymbols([]);
        }
      );
    } else {
      // dispatch({
      //   type: actionTypes.SET_TAB,
      //   tab: 1,
      // });
      setreadnews([]);
      setbookmarknews([]);
    }
    getNews();
  }, [user]);

  const getMostActive = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/mostactive`)
      .then((data) => data.json())
      .then((datajson) => {
        setmostactive(datajson);
      });
  };

  const getTopGainers = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/gainers`)
      .then((data) => data.json())
      .then((datajson) => {
        settopgainers(datajson);
      });
  };

  const getTopLosers = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/losers`)
      .then((data) => data.json())
      .then((datajson) => {
        settoplosers(datajson);
      });
  };

  const getSPY = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=SPY`)
      .then((data) => data.json())
      .then((datajson) => {
        setspy(datajson);
      });
  };

  const getDIA = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=DIA`)
      .then((data) => data.json())
      .then((datajson) => {
        setdia(datajson);
      });
  };

  const getNews = () => {
    let stocks = selectedstocks.join("&ticker=");
    if (user) {
      const newsInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        newsInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setnews([]);
            setloading(false);
          } else {
            let snapVal = snapshot.val();
            if (snapVal.bookmarknews !== undefined && snapVal.bookmarknews !== null) {
              let newsVal = Object.values(snapVal.bookmarknews).map(item => item.news);
              newsVal.sort(function (x, y) {
                let a = moment(x.addedOn);
                let b = moment(y.addedOn);
                return b - a;
              });
              setnews(newsVal);
              setloading(false);
            } else {
              setnews([]);
              setloading(false);
            }
          }
        },
        (err) => {
          setnews([]);
          setloading(false);
        }
      );
    }
  };

  const getHeader = () => {
    return (
      <div className="flex flex-col sticky top-0 z-50 bg-white">
        <div
          className={`flex w-full items-center justify-center items-center ${
            chat.enabled
              ? "flex-col py-6"
              : "flex-col sm:flex-row h-[120px] sm:h-auto"
          }`}
        >
          <div
            className={`flex flex-1 h-[100px] w-full sticky top-0 z-50 items-center ${
              chat.enabled
                ? "justify-center mb-[15px]"
                : "pl-0 sm:pl-4 justify-center sm:justify-start"
            }`}
          >
            <div className="w-4 h-4 mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="#0d5446"
              >
                <path d="M48 0H336C362.5 0 384 21.49 384 48V487.7C384 501.1 373.1 512 359.7 512C354.7 512 349.8 510.5 345.7 507.6L192 400L38.28 507.6C34.19 510.5 29.32 512 24.33 512C10.89 512 0 501.1 0 487.7V48C0 21.49 21.49 0 48 0z" />
              </svg>
            </div>

            <h1 className="font-bold text-2xl text-black text-[#0d5446] mt-1">
              Bookmarked News 
            </h1>
          </div>
          <div
            className={`flex p-2 px-4 border rounded-full ${
              marketmovers ? "bg-[#f0f0f0]" : "bg-white"
            } items-center justify-center hover:bg-[#f0f0f0] ${
              chat.enabled ? "mb-[0px]" : "mr-4"
            }`}
            onClick={() => setmarketmovers(!marketmovers)}
          >
            <p className="text-xs mr-2">How is market looking today?</p>
            <div className="w-2 h-2">
              {marketmovers ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSPY = () => {
    let changePercent = (spy.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-row transition ${
          isUp ? "bg-[#1ab394]" : "bg-[#f44336]"
        } rounded-md mb-3 group mr-2 flex-1 p-4 max-w-[400px]`}
      >
        <div className="flex flex-1 flex-col justify-center items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#0d5446]" : "bg-[#82231d]"
            } rounded-[5px] px-2 py-1`}
          >
            {spy.symbol}
          </div>
          <h3 className="text-md font-semibold text-white mr-3">S & P 500</h3>
        </div>
        <div className="flex flex-1 flex-col justify-center items-end">
          <p className="font-bold text-md text-white">{spy.latestPrice}</p>
          <p className="text-xs text-white">{changePercent}%</p>
        </div>
      </li>
    );
  };

  const renderDIA = () => {
    let changePercent = (dia.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-row transition ${
          isUp ? "bg-[#1ab394]" : "bg-[#f44336]"
        } rounded-md mb-3 group mr-2 flex-1 p-4 max-w-[400px]`}
      >
        <div className="flex flex-1 flex-col justify-center items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#0d5446]" : "bg-[#82231d]"
            } rounded-[5px] px-2 py-1`}
          >
            {dia.symbol}
          </div>
          <h3 className="text-md font-semibold text-white mr-3">Dow Jones</h3>
        </div>
        <div className="flex flex-1 flex-col justify-center items-end">
          <p className="font-bold text-md text-white">{dia.latestPrice}</p>
          <p className="text-xs text-white">{changePercent}%</p>
        </div>
      </li>
    );
  };

  const renderMarketMovers = () => {
    return (
      <div
        className={`flex-col ${
          marketmovers
            ? "p-3 pb-5 pb-0 h-[400px] border-b-[5px]"
            : "h-[0px] overflow-hidden"
        } transition-height transition-slowest ease ${
          user ? "flex" : "sm:hidden"
        }`}
      >
        <div className="flex flex-row">
          {renderSPY()}
          {renderDIA()}
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="text-md font-semibold pr-8">Most Active Stocks:</h3>
          <div>
            {mostactive && mostactive.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll">
                {mostactive.map((stockArr, index) => {
                  return (
                    <NewsStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="text-md font-semibold pr-8">
            Top Gainers (intraday):
          </h3>
          <div>
            {topgainers && topgainers.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll">
                {topgainers.map((stockArr, index) => {
                  return (
                    <NewsStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="text-md font-semibold pr-8">Top Losers (intraday):</h3>
          <div>
            {toplosers && toplosers.length > 0 ? (
              <div className="flex mt-2 overflow-y-scroll">
                {toplosers.map((stockArr, index) => {
                  return (
                    <NewsStockSlider
                      key={index}
                      stockArr={stockArr}
                      storedsymbols={storedsymbols}
                      index={index}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        className={`flex flex-col flex-1 overflow-y-scroll scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
          user ? "" : "rounded-r-3xl"
        }`}
      >
        {renderMarketMovers()}
        {<Sidebar mobile={true} />}
        {getHeader()}
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div
            role="status"
            className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex-1 scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
        user ? "" : "sm:rounded-r-3xl"
      } ${send.enabled ? "overflow-y-hidden" : "overflow-y-scroll"}`}
    >
      {renderMarketMovers()}
      {<Sidebar mobile={true} />}
      {getHeader()}
      <ul className="mt-0 relative">
        {sharednews.newsTitle && (
          <NewsCard
            key={"sharednews"}
            news={sharednews}
            bookmarknews={bookmarknews}
            readnews={readnews}
            storedsymbols={storedsymbols}
          />
        )}
        {news &&
          news.map((news, index) => {
            return (
              <NewsCard
                key={index}
                news={news}
                bookmarknews={bookmarknews}
                readnews={readnews}
                storedsymbols={storedsymbols}
              />
            );
          })}
      </ul>
    </div>
  );
}

export default BookmarkTab;
